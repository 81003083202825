@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300&family=Yaldevi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yaldevi:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

* {
  padding: 0;
  margin: 0;
}

html,
body {
  background: #000;
  font-family: "Ubuntu", sans-serif;
}

/* Hide controls */
.jw-display-icon-rewind,
.jw-icon-next,
.jw-settings-sharing,
.jw-playlist-btn,
.jw-related-btn,
.jw-icon-cc,
.jw-icon-cast,
.jw-icon-airplay,
.jw-icon-settings {
  visibility: hidden;
}
